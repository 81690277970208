/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-03-31",
    versionChannel: "nightly",
    buildDate: "2025-03-31T00:07:12.673Z",
    commitHash: "0dbe134ae758bf4b42d855c72e8ab09e2e2a8630",
};
